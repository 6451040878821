import React, { useState } from 'react'
import {
  ArrowLeftIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  CreditCardIcon,
  LockClosedIcon,
} from '@heroicons/react/outline'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router'
import { flushSync } from 'react-dom'
import { useQueryClient } from '@tanstack/react-query'

import HOME from '../../assets/icons/Home.svg'
import MORE from '../../assets/icons/More.svg'
import LIBRARY from '../../assets/icons/Library.svg'
import CALENDAR from '../../assets/icons/Calendar.svg'

import HOME_WHITE from '../../assets/icons/HomeWhite.svg'
import LIBRARY_WHITE from '../../assets/icons/LibraryWhite.svg'
import CALENDAR_WHITE from '../../assets/icons/CalendarWhite.svg'

import MoreSlider from './MoreSlider'
import AppLogo from '../AppLogo'
import NavButton from './NavButton'
import AccountButton from './AccountButton'
import { auth } from '../../config/firebase'
import { useAuth } from '../../contexts/AuthProvider'

export type Option = {
  id: number
  to?: string
  text: string
  iconSvg?: string
  icon?: JSX.Element
  handleOnClick?: () => void
}

const generateMenuItems = (
  hasAnyPayments: boolean,
  showPermissionSettings: boolean,
  onClickSignOut: () => {}
): Option[] => {
  const menuItems: Option[] = [
    {
      id: 1,
      to: '/account-settings',
      text: 'Account Settings',
      icon: <CogIcon className="mr-3 h-5 w-5 text-text-label" />,
    },
    {
      id: 4,
      to: '/contact',
      text: 'Contact Us',
      icon: <QuestionMarkCircleIcon className="mr-3 h-5 w-5 text-text-label" />,
    },
    {
      id: 5,
      handleOnClick: onClickSignOut,
      text: 'Sign Out',
      icon: <ArrowLeftIcon className="mr-3 h-5 w-5 text-text-label" />,
    },
  ]

  if (showPermissionSettings)
    menuItems.push({
      id: 2,
      to: '/permission-settings',
      text: 'Permission Settings',
      icon: <LockClosedIcon className="mr-3 h-5 w-5 text-text-label" />,
    })

  if (hasAnyPayments) {
    menuItems.push({
      id: 3,
      to: '/payment-history',
      text: 'Payment History',
      icon: <CreditCardIcon className="mr-3 h-5 w-5 text-text-label" />,
    })
  }

  return menuItems.sort((a, b) => a.id - b.id)
}

const Navbar: React.FC = () => {
  const queryClient = useQueryClient()
  const [openMore, setOpenMore] = useState(false)
  const { user, setUser } = useAuth()
  const navigate = useNavigate()

  const onClickSignOut = async () =>
    flushSync(async () => {
      setUser(null)
      await signOut(auth)
      navigate('/sign-in')
      queryClient.invalidateQueries()
      queryClient.clear()
    })

  const showPermissionSettings = user.roster.some(
    (p) => p?.relationship?.key !== 'myself' && p?.age >= 12
  )
  const hasAnyPayments = user.payments?.length > 0
  const computedMenuItems = generateMenuItems(
    hasAnyPayments,
    showPermissionSettings,
    onClickSignOut
  )

  return (
    <div className="group relative">
      <div className="fixed left-0 bottom-0 z-30 flex h-16 w-screen justify-evenly bg-white px-1 py-2 shadow-subtle lg:top-0 lg:h-screen lg:w-auto lg:flex-col lg:items-center lg:justify-start lg:gap-4 lg:bg-text-primary lg:px-4 lg:py-20 lg:group-hover:w-52">
        <div className="mr-auto hidden lg:mb-20 lg:flex">
          <AppLogo smallLogo classes="object-cover" white />
        </div>

        {/* Buttons */}
        <NavButton
          icon={HOME}
          iconWhite={HOME_WHITE}
          text="Home"
          to="/dashboard"
        />
        <NavButton
          icon={CALENDAR}
          iconWhite={CALENDAR_WHITE}
          text="Bookings"
          to="/bookings"
        />
        {user.roster.every((p) => !p.isDisabled) && (
          <NavButton
            icon={LIBRARY}
            iconWhite={LIBRARY_WHITE}
            text="Resources"
            to="/resources"
          />
        )}
        <NavButton
          icon={MORE}
          text="More"
          handleOnClick={() => setOpenMore(true)}
          hideDesktop
        />

        {/* Avatar */}
        <AccountButton
          name={`${user.data.firstName} ${user.data.lastName}`}
          menuItems={computedMenuItems}
        />

        {/* More slider */}
        <MoreSlider
          open={openMore}
          setOpen={setOpenMore}
          menuItems={computedMenuItems}
        />
      </div>
    </div>
  )
}

export default Navbar
