import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'

import SPEAK_LANGUAGE from '../../assets/icons/speakLanguage.svg'
import THERAPY from '../../assets/icons/therapy.svg'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import {
  THERAPIST_NETWORK_INFORMATIONS,
  SERVICE_LINES_ARRAY,
} from '../../constants/serviceLine'
import type { Therapist } from '../../types/Therapist'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type {
  ProviderBookingLocationState,
  SelectedDateAndSlot,
} from '../../types/Booking'
import type { ServiceLine } from '../../types/ServiceLine'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import { LICENSE_CREDENTIAL, states } from '../../constants/values'
import type { State } from '../../types/State'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import { usePatient } from '../../contexts/PatientProvider'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import useIsSelfPay from '../../hooks/useIsSelfPay'
import CalendarWithSlots from './components/CalendarWithSlots'
import { ProviderStatus } from './Providers'
import WantDifferentTherapistModal from '../../components/Modals/WantDifferentTherapistModal'
import { useCheckProviderInInsuranceNetwork } from '../../queries/booking/ProviderInsuranceNetwork'
import {
  isCreditCardExpired,
  willCreditCardExpireBeforeSession,
} from '../../helpers/payments'
import type { ResultGetProviders } from '../../queries/booking/UseGetProviders'
import useGetProviders from '../../queries/booking/UseGetProviders'
import useIsPatientPayCOS from '../../hooks/useIsPatientPayCOS'
import useProductAdditionalSessionsType from '../../hooks/useProductAdditionalSessionsType'
import { useGetPatientPacket } from '../../queries/onboarding/GetPatientPacket'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import type { Product } from '../../types/User'
import { flushSync } from 'react-dom'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import type { noProviderItem } from '../../types/Provider'
import type { Session } from '../../types/Session'
import { useProviderContext } from '../../contexts/ProviderContext'
import CrisisMessaging from '../../components/CrisisMessaging'
import { useGetPrices } from '../../queries/payments/GetPrices'
import { useGetSessionDurations } from '../../queries/booking/GetSessionDurations'

const Provider: React.FC = (): JSX.Element => {
  const addToast = useToastContext()
  const { providerId } = useParams()
  const location = useLocation()
  const { user } = useAuth()
  const { patient, setPatient } = usePatient()
  const { wantDifferentTherapist } = useProviderContext()
  const serviceLine: ServiceLine = useCurrentServiceLine()
  const navigate = useNavigate()
  const isPatientPayCOS = useIsPatientPayCOS()
  const additionalSessionsType: string = useProductAdditionalSessionsType()
  const isSelfPay = useIsSelfPay()
  const { mutate: callUpdateProfile } = useUpdateProfile()
  const [newAddCarePlanLoading, setNewAddCarePlanLoading] =
    useState<boolean>(true)
  const paymentMethod = user.paymentMethod
  const { data: patientPacket, isLoading: isLoadingGetPatientPacket } =
    useGetPatientPacket({
      patientId: patient.id,
    })
  const { data: prices } = useGetPrices()

  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, serviceLine?.displayName),
    [patient, serviceLine]
  )
  const currentCarePlan: CarePlan = patient?.conditions
    ?.find((c: Condition) => !c.isIep)
    ?.carePlans?.find(
      (cp: CarePlan) => cp.displayName === serviceLine.displayName
    )

  const { data: resultProviders, isLoading: isLoadingResultProviders } =
    useGetProviders({
      patients: [patient],
      enabled: Boolean(patient),
    })

  const [openWantDifferentProvider, setOpenWantDifferentProvider] =
    useState<boolean>(false)
  const [currentProvider, setCurrentProvider] = useState<Therapist>(null)
  const [selectedDateAndSlot, setSelectedDateAndSlot] =
    useState<SelectedDateAndSlot>(null)
  const [activeCalendar, setActiveCalendar] = useState<number>(null)
  const [sessionDuration, setSessionDuration] = useState<number>(null)

  useEffect(() => {
    if (!resultProviders?.length) return

    const provider = resultProviders[0].therapists.find(
      (t: Therapist) => t.id === providerId
    )

    if (!provider) {
      navigate('/404', { replace: true })
      return
    }

    setCurrentProvider(provider)
    // TODO - Add Mixpanel log
    // setExtraLog(
    //   `Booking / ${serviceLine.displayName} / Provider Screen / ${provider.preferredName}`
    // )
  }, [resultProviders])

  const handleNavigateNext = useCallback(() => {
    if (!selectedDateAndSlot) return

    const stateParams: ProviderBookingLocationState = {
      provider: currentProvider,
      selectedDate: selectedDateAndSlot.selectedDate,
      selectedTimeSlot: selectedDateAndSlot.selectedTimeSlot,
      cost: (sessionDuration / 30) * prices[sessionDuration],
    }

    const isSelfPayOrCommercialInsurance =
      isSelfPay || patient.insurance?.insurance?.insuranceType !== 'Medicaid'

    const cardExpired =
      paymentMethod &&
      isCreditCardExpired(
        paymentMethod.cardExpirationMonth,
        paymentMethod.cardExpirationYear
      )

    const cardWillExpireBeforeSession =
      paymentMethod &&
      willCreditCardExpireBeforeSession(
        selectedDateAndSlot.selectedDate,
        paymentMethod.cardExpirationMonth,
        paymentMethod.cardExpirationYear
      )

    const confirmationPage = `confirmation`

    const shouldGoToPatientPacket = !patientPacket?.signed

    const nextRouteAfterPacketCheck = isPatientPayCOS
      ? !paymentMethod || !currentCarePlan.billingInformationConfirmed
        ? `billing-information`
        : isSelfPayOrCommercialInsurance
        ? cardExpired
          ? `card-information?cardStatus=expired`
          : cardWillExpireBeforeSession
          ? `card-information?cardStatus=willExpireBeforeSession`
          : confirmationPage
        : confirmationPage
      : confirmationPage

    navigate(
      shouldGoToPatientPacket
        ? `patient-packet-signature`
        : `${nextRouteAfterPacketCheck}`,
      {
        state: {
          ...stateParams,
          redirectAfter: shouldGoToPatientPacket
            ? `${location.pathname}/${nextRouteAfterPacketCheck}`
            : null,
        },
      }
    )
  }, [currentProvider, selectedDateAndSlot, sessionDuration])

  const isProviderInsuranceNetworkEnabled = Boolean(
    !patient.unassociatedInsurance &&
      patient.insurance?.id &&
      currentProvider?.id
  )

  const {
    data: providerInsuranceNetwork,
    isLoading: isLoadingProviderInsuranceCheck,
  } = useCheckProviderInInsuranceNetwork({
    patient,
    serviceType: serviceLine.serviceType,
    language: patient.preferredLanguage,
    licenseJurisdiction: states.find(
      (state: State) => state?.name === patient?.state
    )?.abbrev,
    providerId: currentProvider?.id,
    insuranceId: patient.insurance?.id,
    enabled: isProviderInsuranceNetworkEnabled,
  })

  const { data: sessionDurations, isLoading: isLoadingSessionDurations } =
    useGetSessionDurations({
      currentCarePlan,
      isAssessment,
    })

  const usingFreeSessions: boolean =
    currentCarePlan?.remainingSessions <= currentCarePlan?.allowedSessions &&
    Boolean(currentCarePlan.allowedSessions)
  const differentTherapists: boolean =
    resultProviders?.[0]?.therapists?.filter(
      (t: Therapist) => t.serviceLine === serviceLine.displayName
    )?.length > 1
  const alreadyHasTherapist: boolean = currentCarePlan?.sessions?.length > 0
  const insuranceCoversOtherTherapists =
    providerInsuranceNetwork?.otherProvidersInNetwork?.length > 0
  const showWantDifferentTherapist: boolean =
    differentTherapists &&
    alreadyHasTherapist &&
    (isSelfPay || insuranceCoversOtherTherapists || usingFreeSessions)

  const isLoadingPage: boolean =
    isLoadingResultProviders ||
    !resultProviders?.length ||
    !currentProvider ||
    newAddCarePlanLoading ||
    isLoadingSessionDurations ||
    (isProviderInsuranceNetworkEnabled && isLoadingProviderInsuranceCheck)

  const hoursNotice = isAssessment
    ? currentProvider?.hoursNoticeAssessment
    : currentProvider?.hoursNoticeDirectSession

  // create new condition attribute for IEP patient if eligibile for Gen Ed
  useEffect(() => {
    if (isLoadingResultProviders || !resultProviders?.length) return

    if (
      !patient?.isIep ||
      patient?.conditions
        ?.find((c: Condition) => !c.isIep)
        ?.carePlans.some(
          (cp: CarePlan) => cp.displayName === serviceLine.displayName
        )
    ) {
      setNewAddCarePlanLoading(false)
      return
    }

    const currentPatient = cloneDeep(patient)

    const nonIepProduct: Product = user?.products?.find(
      (p: Product) =>
        !p.isIep && p.serviceLine.serviceId === serviceLine.serviceId
    )

    const newCarePlans = []
    newCarePlans.push({
      serviceType: serviceLine.serviceType,
      language: currentPatient.preferredLanguage || 'English',
      productId: nonIepProduct.id,
    })

    callUpdateProfile(
      {
        patient: {
          ...currentPatient,
          conditions: [{ id: null, carePlans: newCarePlans }],
        },
        user,
      },
      {
        onError: () => {
          addToast('error', 'Something went wrong.')
          setNewAddCarePlanLoading(false)
        },
        onSuccess: (newPatient: Patient) => {
          flushSync(() => setPatient(newPatient))
          setNewAddCarePlanLoading(false)
        },
      }
    )
  }, [isLoadingResultProviders, resultProviders])

  // Check if Provider still at us
  useEffect(() => {
    if (wantDifferentTherapist || !patient || !resultProviders?.length) return

    const currentCarePlan: CarePlan = patient?.conditions
      ?.find((c: Condition) => !c.isIep)
      ?.carePlans.find(
        (cp: CarePlan) => cp.displayName === serviceLine.displayName
      )

    // no provider selected for this patient yet OR care plan does not exist 👉
    if (!currentCarePlan?.providerId) return

    const availableTherapistsInServiceLine = resultProviders
      ?.find((r: ResultGetProviders) => r.patient.id === patient.id)
      ?.therapists?.filter(
        (t: Therapist) => t.serviceLine === currentCarePlan.displayName
      )

    const provider = availableTherapistsInServiceLine?.find(
      (t: Therapist) => t.id === currentCarePlan.providerId
    )

    // provider still at us 👉
    if (provider) {
      return
    }
    // provider no longer at us 👇
    else {
      // get a random session from care plan to use information about therapist
      const randomSessionWithProvider = currentCarePlan?.sessions?.find(
        (s: Session) => s.therapist.id === currentCarePlan.providerId
      )

      if (randomSessionWithProvider) {
        const therapistUnavailableNavigateState: noProviderItem = {
          patient,
          serviceLine,
          provider: randomSessionWithProvider.therapist,
          otherTherapistOptions: availableTherapistsInServiceLine,
        }

        navigate('/therapist-unavailable', {
          state: therapistUnavailableNavigateState,
        })
      }
    }
  }, [wantDifferentTherapist, patient, resultProviders])

  if (!patient) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-xl items-center text-center sm:px-6">
      <h2 className="text-base font-semibold sm:text-2xl sm:font-medium">
        Book a session with {isAssessment ? 'a' : 'your'} therapist!
      </h2>
      <div className="flex flex-col gap-6 sm:gap-10">
        <CrisisMessaging />
        <div className="flex flex-col items-start gap-4 p-4 sm:gap-8 sm:p-0">
          <div className="flex flex-row items-center gap-6 sm:gap-16">
            {isLoadingPage ? (
              <div className="h-14 w-14 animate-pulse rounded-full bg-components-fields sm:h-30 sm:w-30 sm:border-4 sm:border-components-paleBlue" />
            ) : (
              <img
                className="h-14 w-14 rounded-full object-cover sm:h-30 sm:w-30 sm:border-4 sm:border-components-paleBlue"
                src={currentProvider?.src}
                alt="Therapist"
              />
            )}
            <div className="flex flex-col items-start gap-0 sm:gap-1">
              {isLoadingPage ? (
                <div className="flex flex-col gap-2">
                  <div className="h-6 w-20 animate-pulse rounded-md bg-components-fields xs:h-4" />
                  <div className="h-6 w-30 animate-pulse rounded-md bg-components-fields xs:h-4" />
                </div>
              ) : (
                <>
                  <h1 className="text-left text-sm font-semibold leading-8 sm:text-2xl">
                    {currentProvider?.preferredName}
                  </h1>
                  <h2 className="text-label text-left text-xs font-semibold sm:text-base">
                    {currentProvider?.licenseCredential ||
                      LICENSE_CREDENTIAL[
                        SERVICE_LINES_ARRAY.find(
                          (line: ServiceLine) =>
                            line.displayName === currentProvider?.serviceLine
                        )?.serviceType
                      ]}
                  </h2>
                </>
              )}

              {!isLoadingPage &&
                isPatientPayCOS &&
                isProviderInsuranceNetworkEnabled &&
                !isSelfPay && (
                  <ProviderStatus
                    showOnMobile
                    therapistNetworkInformation={
                      providerInsuranceNetwork?.currentProviderInNetwork
                        ? THERAPIST_NETWORK_INFORMATIONS.inNetwork
                        : THERAPIST_NETWORK_INFORMATIONS.outOfNetwork
                    }
                  />
                )}
            </div>
          </div>
          <div className="flex flex-col items-start gap-1">
            <div className="flex flex-row gap-1">
              <img src={THERAPY} alt="Support" className="h-5 w-5" />
              {isLoadingPage ? (
                <div className="h-6 w-20 animate-pulse rounded-md bg-components-fields xs:h-4" />
              ) : (
                <p className="text-sm font-normal capitalize">
                  {currentProvider?.serviceLine}
                </p>
              )}
            </div>
            <div className="flex flex-row gap-1">
              <img src={SPEAK_LANGUAGE} alt="language" className="h-5 w-5" />
              {isLoadingPage ? (
                <div className="h-6 w-20 animate-pulse rounded-md bg-components-fields xs:h-4" />
              ) : (
                <p className="text-left text-sm font-normal">
                  {React.Children.toArray(
                    currentProvider?.languages.map((language, i) => (
                      <span>
                        {language}
                        {i < currentProvider?.languages?.length - 1 && ', '}
                      </span>
                    ))
                  )}
                </p>
              )}
            </div>
          </div>
          {isLoadingPage ? (
            <div className="flex w-full flex-col gap-2">
              <div className="h-6 w-full animate-pulse rounded-md bg-components-fields xs:h-4" />
              <div className="h-6 w-full animate-pulse rounded-md bg-components-fields xs:h-4" />
            </div>
          ) : (
            <p className="text-start text-sm font-normal sm:text-base">
              {currentProvider?.description}
            </p>
          )}
          {!isLoadingPage && showWantDifferentTherapist && (
            <>
              <button
                className="text-sm text-text-secondary underline"
                onClick={() => setOpenWantDifferentProvider(true)}
              >
                Want a different therapist?
              </button>
              <WantDifferentTherapistModal
                open={openWantDifferentProvider}
                setOpen={setOpenWantDifferentProvider}
                serviceLine={serviceLine}
                currentProviderName={currentProvider?.preferredName}
              />
            </>
          )}
        </div>
        <div className="h-0.5 w-full bg-components-fields" />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-semibold sm:text-lg">
              Reserve your {isAssessment ? 'first' : ''} session{' '}
              {!isLoadingPage && `with ${currentProvider?.preferredName}`}
            </h3>
            <p className="text-sm font-normal sm:text-base">
              Click below to view session availability
            </p>
          </div>
          {isLoadingPage ? (
            <div className="h-36 w-[528px] animate-pulse rounded-2xl bg-components-fields xs:h-26 xs:w-[358px]" />
          ) : (
            <div className="flex flex-col gap-6">
              {React.Children.toArray(
                sessionDurations?.map((duration: number, index: number) => (
                  // show calendar for each session duration
                  <CalendarWithSlots
                    hoursNotice={hoursNotice}
                    setSelectedDateAndSlot={(dateAndSlot) => {
                      setSelectedDateAndSlot(dateAndSlot)
                      setSessionDuration(duration)
                    }}
                    sessionDuration={duration}
                    isAssessment={isAssessment}
                    id={index}
                    activeCalendar={activeCalendar}
                    setActiveCalendar={setActiveCalendar}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-4 pt-8 sm:gap-12 sm:pt-0">
        <div className="flex w-full flex-col gap-4 xs:gap-2">
          {/* 0 means unlimited number of sessions */}
          {!isLoadingPage &&
            currentCarePlan?.remainingSessions > 0 &&
            Boolean(currentCarePlan.allowedSessions) &&
            additionalSessionsType !== 'CLIENT_PAY' &&
            !currentCarePlan.bulkModel.isBulkModel && (
              <p className="text-sm font-semibold text-text-secondary xs:text-xs">
                {currentCarePlan.remainingSessions} covered sessions remaining
                (30 min = 1 session)
              </p>
            )}
          <div className="flex w-full flex-row gap-2 sm:gap-4">
            <button
              onClick={() => navigate(location?.state?.from || -1)}
              className={`${tertiaryButtonClass} w-full`}
              disabled={isLoadingPage}
            >
              <ChevronLeftIcon className="h-5 w-5" />
              <p>Back</p>
            </button>
            <button
              onClick={handleNavigateNext}
              disabled={
                !selectedDateAndSlot ||
                isLoadingGetPatientPacket ||
                isLoadingPage
              }
              className={`${primaryButtonClass} w-full`}
            >
              <p>Next</p>
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default Provider
