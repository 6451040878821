import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid'
import React, { useMemo, useState } from 'react'

import SessionComponent from '../../../components/SessionComponent'
import type { Session } from '../../../types/Session'
import type { CarePlan, Condition } from '../../../types/Patient'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import type { ServiceLine } from '../../../types/ServiceLine'
import { usePatient } from '../../../contexts/PatientProvider'
import { newDate } from '../../../helpers/generic'
import { useAuth } from '../../../contexts/AuthProvider'
import BookNewSessionBtn from './BookNewSessionBtn'
import { isValidPastSession } from '../../../helpers/bookSessionStatusUtils'

const SessionsTab: React.FC = () => {
  const { user } = useAuth()
  const { patient } = usePatient()

  const allSessions = useMemo(() => {
    return patient?.conditions
      .flatMap((c: Condition) => c.carePlans)
      .flatMap((c: CarePlan) => c.sessions)
      .sort(
        (a: Session, b: Session) =>
          new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
      )
  }, [patient, user])

  // upcoming sessions
  const upcomingSessions: Session[] = useMemo(() => {
    if (!allSessions?.length) return []

    return allSessions
      .filter(
        (session: Session) =>
          session.status === 'pending' &&
          newDate(new Date(session.endTime), patient.timeZone) >
            newDate(new Date(), patient.timeZone)
      )
      .sort((sessionA: Session, sessionB: Session) => {
        const startTimeA: number = new Date(sessionA.startTime).getTime()
        const startTimeB: number = new Date(sessionB.startTime).getTime()

        return startTimeA - startTimeB
      })
  }, [allSessions])

  // previous sessions
  const previousSessions = useMemo(
    () =>
      allSessions.filter((session: Session) =>
        isValidPastSession(session, patient)
      ),
    [allSessions]
  )

  return (
    <div className="flex flex-col gap-8 xs:gap-6">
      <UpcomingSessions sessions={upcomingSessions} />

      {/* Divider */}
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>

      <PreviousSessions sessions={previousSessions} />
    </div>
  )
}

const UpcomingSessions: React.FC<{
  sessions: Session[]
}> = ({ sessions }) => {
  const [sessionIndex, setSessionIndex] = useState<number>(1)

  return (
    <div className="flex flex-col gap-8 text-text-primary xs:gap-4">
      {/* Header */}
      <div className="flex sm:items-center sm:justify-between xs:flex-col-reverse xs:gap-6">
        {/* Title */}
        <p className="text-2xl font-medium text-text-primary xs:text-base xs:font-semibold">
          Upcoming Sessions
        </p>

        {/* Divider */}
        <div className="relative sm:hidden">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
        </div>

        {/* Button */}
        <BookNewSessionBtn tooltipPosition="left" />
      </div>

      {/* Content */}
      {!sessions?.length ? (
        <p className="text-sm">No sessions to display.</p>
      ) : (
        <div className="flex flex-col gap-6">
          <SessionComponent session={sessions[sessionIndex - 1]} minimal />

          {/* Paginator */}
          <div className="flex items-center justify-between gap-8 xs:flex-col xs:gap-2">
            {/* Text */}
            <p className="text-sm">
              Showing{' '}
              <span className="font-semibold">
                {Boolean(sessions?.length) ? sessionIndex : 0}
              </span>{' '}
              of <span className="font-semibold">{sessions?.length}</span>{' '}
              sessions
            </p>
            {/* Buttons */}
            <div className="flex items-center gap-8 xs:gap-6">
              <button
                className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
                disabled={sessionIndex === 1}
                onClick={() => setSessionIndex((current) => (current -= 1))}
              >
                <ChevronLeftIcon className="h-5 w-5" /> Previous
              </button>
              <button
                className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
                disabled={
                  sessionIndex === sessions?.length ||
                  !Boolean(sessions?.length)
                }
                onClick={() => setSessionIndex((current) => (current += 1))}
              >
                Next
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const PreviousSessions: React.FC<{
  sessions: Session[]
}> = ({ sessions }) => {
  const presentServiceLines: string[] = useMemo(
    () =>
      Object.values(
        sessions.reduce((acc, curr) => {
          if (!acc[curr.type]) {
            acc[curr.type] = curr.type
          }

          return acc
        }, {})
      ),
    [sessions]
  )

  return (
    <div className="flex flex-col gap-8 text-text-primary xs:gap-4">
      {/* Header */}
      <div className="flex items-center sm:justify-between">
        {/* Title */}
        <p className="text-2xl font-medium text-text-primary xs:text-base xs:font-semibold">
          Previous Sessions
        </p>
      </div>

      {/* Content */}
      {React.Children.toArray(
        presentServiceLines.map((serviceLineName: string) => (
          <PrevSessionComponent
            title={serviceLineName}
            image={
              SERVICE_LINES_ARRAY.find(
                (line: ServiceLine) => line.displayName === serviceLineName
              )?.avatar
            }
            sessions={sessions.filter(
              (s: Session) => s.therapist.serviceLine === serviceLineName
            )}
          />
        ))
      )}

      {!presentServiceLines?.length && (
        <p className="text-sm">No sessions to display.</p>
      )}
    </div>
  )
}

const PrevSessionComponent: React.FC<{
  title: string
  image: string
  sessions: Session[]
}> = ({ sessions, title, image }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(1)

  return (
    <div className="border border-components-fields sm:rounded-2xl sm:p-8 xs:rounded-lg xs:p-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        {/* Left */}
        <div className="flex items-center">
          <img src={image} className="h-5 w-5" alt="therapy-type" />
          <div className="ml-2 sm:flex">
            <p className="font-semibold text-text-primary sm:text-lg xs:text-sm">
              {title}
            </p>
          </div>
        </div>

        {/* Right */}
        <button
          className="flex items-center text-cta-default"
          onClick={() => setExpanded((current) => !current)}
        >
          <p className="mr-1 text-base font-semibold sm:block xs:hidden xs:text-sm">
            {expanded ? 'Collapse' : 'Expand'}
          </p>
          {expanded ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Expanded view */}
      <div
        className={`${
          expanded ? '' : 'hidden'
        } flex flex-col gap-6 pt-10 xs:gap-4 xs:pt-4`}
      >
        {React.Children.toArray(
          sessions
            .slice(index - 1, index + 3)
            .map((session: Session) => (
              <SessionComponent session={session} showStatus />
            ))
        )}
        {/* Paginator */}
        <div className="flex items-center justify-between gap-8 xs:flex-col xs:gap-2">
          {/* Text */}
          <p className="text-sm">
            {sessions?.length <= 4 ? (
              <>
                Showing{' '}
                <span className="font-semibold">{sessions?.length}</span>{' '}
                sessions
              </>
            ) : index + 3 >= sessions?.length ? (
              <>
                Showing <span className="font-semibold">{index}</span> to{' '}
                <span className="font-semibold">{sessions?.length}</span> of{' '}
                <span className="font-semibold">{sessions?.length}</span>{' '}
                sessions
              </>
            ) : (
              <>
                Showing <span className="font-semibold">{index}</span> to{' '}
                <span className="font-semibold">{index + 3}</span> of{' '}
                <span className="font-semibold">{sessions?.length}</span>{' '}
                sessions
              </>
            )}
          </p>
          {/* Buttons */}
          <div className="flex items-center gap-8 xs:gap-6">
            <button
              className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
              disabled={index === 1}
              onClick={() => setIndex((current) => (current -= 4))}
            >
              <ChevronLeftIcon className="h-5 w-5" /> Previous
            </button>
            <button
              className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
              disabled={sessions?.length <= 4 || index + 3 >= sessions?.length}
              onClick={() => setIndex((current) => (current += 4))}
            >
              Next
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionsTab
