import React, { useEffect, useState } from 'react'
import { PencilIcon, RefreshIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'
import { useLocation } from 'react-router-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import { usePatient } from '../../../contexts/PatientProvider'
import type { ResourceTopic } from '../../../types/Resources'
import CenteredModal from '../../../components/CenteredModal'
import { InterestsBody } from '../../onboarding/InterestScreen'
import { useDeleteTopics } from '../../../mutations/resources/DeleteTopics'
import { useSaveInterest } from '../../../mutations/onboarding/SaveInterests'
import { useToastContext } from '../../../contexts/ToastContext'
import { useAuth } from '../../../contexts/AuthProvider'
import type { Patient } from '../../../types/Patient'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { getSourceFromLocation } from '../../../helpers/utils'

const TopicsTab: React.FC = () => {
  const location = useLocation()
  const addToast = useToastContext()
  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const [editMode, setEditMode] = useState<boolean>(false)
  const initialTopics: ResourceTopic[] = patient?.interests || []
  const [selectedTopics, setSelectedTopics] =
    useState<ResourceTopic[]>(initialTopics)

  const { mutate: callDeleteTopics, isLoading: isLoadingDeleteTopics } =
    useDeleteTopics()
  const { mutate: callAddNewTopics, isLoading: isLoadingAddingNewTopics } =
    useSaveInterest()

  const handleSaveEditTopics = () =>
    callDeleteTopics(
      { topics: initialTopics },
      {
        onSettled: () =>
          callAddNewTopics(
            {
              interests: selectedTopics.filter(
                (t: ResourceTopic) => t.key !== 'OTHER'
              ),
              patientId: patient.id,
            },
            {
              onSettled: (interests: ResourceTopic[]) => {
                const newPatient: Partial<Patient> = {
                  ...patient,
                  interests,
                }
                const patientIdx = user.roster.findIndex(
                  (p: Patient) => p.id === patient.id
                )
                const newUser = cloneDeep(user)
                newUser.roster[patientIdx] = newPatient
                setUser(newUser)
                setPatient(newPatient)

                trackMixPanel({
                  eventName: MIXPANEL_EVENT.ACCOUNT_UPDATED,
                  properties: {
                    category: 'Topics of Interest',
                    accountId: user.data.id,
                    source: getSourceFromLocation(location),
                  },
                })

                addToast('success', 'Topics of interest updated successfully.')
                setEditMode(false)
              },
            }
          ),
      }
    )

  useEffect(() => {
    if (!editMode) setSelectedTopics(initialTopics)
  }, [editMode])

  return (
    <div className="flex flex-col gap-6 sm:gap-12">
      {/* Header */}
      <div className="flex items-center justify-between">
        <p className="font-mediu text-2xl xs:text-base xs:font-semibold">
          My Interests
        </p>

        {/* Edit */}
        {!patient.isDisabled && (
          <div className="flex justify-end xs:hidden">
            <button
              className={tertiaryButtonClass}
              type="button"
              onClick={(e) => {
                setEditMode(true)
                e.currentTarget.blur()
              }}
            >
              <PencilIcon className="h-5 w-5" />{' '}
              <span className="xs:hidden">Edit</span>
            </button>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="flex flex-wrap gap-2">
        {patient?.interests?.length ? (
          React.Children.toArray(
            patient?.interests.map((t: ResourceTopic) => (
              <div className="rounded-xl border border-transparent bg-components-fillBorders p-3 xs:text-xs">
                <p className="font-semibold">{t?.name}</p>
              </div>
            ))
          )
        ) : (
          <p className="w-full text-center text-base text-text-secondary">
            You don't have any interests.
          </p>
        )}
      </div>

      {/* Edit */}
      {!patient.isDisabled && (
        <button
          className={`${tertiaryButtonClass} w-full sm:hidden`}
          type="button"
          onClick={(e) => {
            setEditMode(true)
            e.currentTarget.blur()
          }}
        >
          <PencilIcon className="h-5 w-5" /> <span>Edit</span>
        </button>
      )}

      {/* Edit topics */}
      <CenteredModal open={editMode} setOpen={setEditMode}>
        <p className="mb-6 text-lg font-semibold xs:text-center xs:text-base">
          Edit Topics of Interest
        </p>

        {/* Interests */}
        <div className="max-h-[60vh] overflow-y-auto pb-2 pr-2">
          <InterestsBody
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
          />
        </div>

        {/* Buttons */}
        <div className="mt-6 flex w-full justify-end gap-4">
          <button
            type="button"
            className={`xs:mt-2 ${tertiaryButtonClass}`}
            onClick={() => {
              setSelectedTopics(initialTopics)
              setEditMode(false)
            }}
            disabled={isLoadingAddingNewTopics || isLoadingDeleteTopics}
          >
            Cancel
          </button>
          <button
            className={`xs:mr-0 xs:mt-2 ${primaryButtonClass}`}
            onClick={handleSaveEditTopics}
            disabled={
              isLoadingAddingNewTopics ||
              isLoadingDeleteTopics ||
              (initialTopics?.length === selectedTopics?.length &&
                initialTopics
                  .sort()
                  .every(
                    (value, index) => value === selectedTopics.sort()[index]
                  ))
            }
          >
            {isLoadingAddingNewTopics || isLoadingDeleteTopics ? (
              <>
                <RefreshIcon className="loader h-5 w-5 text-white" /> Loading
              </>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </CenteredModal>
    </div>
  )
}

export default TopicsTab
