import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusIcon, RefreshIcon } from '@heroicons/react/solid'

import {
  primaryButtonClass,
  secondaryButtonClass,
} from '../../../constants/classConstants'
import { usePatient } from '../../../contexts/PatientProvider'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import type { ServiceLine } from '../../../types/ServiceLine'
import type { ResultGetProviders } from '../../../queries/booking/UseGetProviders'
import useGetProviders from '../../../queries/booking/UseGetProviders'
import { useAuth } from '../../../contexts/AuthProvider'
import { useGetClientServiceLines } from '../../../queries/onboarding/GetClientServiceLines'
import type { CarePlan, Condition, Patient } from '../../../types/Patient'
import type { Therapist } from '../../../types/Therapist'
import DottedLargeButton from '../../../components/DottedLargeButton'
import BookingStatusTooltip from '../../../components/BookingStatusTooltip'
import { states } from '../../../constants/values'
import getAvatarSrc from '../../../helpers/getAvatarSrc'

const BookNewSessionBtn: React.FC<{
  dottedLargeBtn?: boolean
  withAvatarsBtn?: boolean
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
  withSpecificAvatars?: (Patient | Partial<Patient>)[]
  onClickBookNewSession?: () => void
}> = ({
  dottedLargeBtn = false,
  withAvatarsBtn = false,
  tooltipPosition = 'bottom',
  withSpecificAvatars = [],
  onClickBookNewSession = () => {},
}) => {
  const { user } = useAuth()
  const { patient, setPatient } = usePatient()
  const navigate = useNavigate()
  const [isDisabledBooking, setIsDisabledBooking] = useState<boolean>(false)
  const [bookableLines, setBookableLines] = useState<ServiceLine[]>([])
  const [providerNotAvailable, setProviderNotAvailable] =
    useState<boolean>(false)

  const { data: clientAvailableSLs, isLoading: isLoadingClientAvailableSLs } =
    useGetClientServiceLines({
      clientId: user.data.clientId,
      clientType: user.data.clientData.clientType,
    })
  const { data: resultProviders, isLoading: isLoadingUseGetProviders } =
    useGetProviders({
      patients: user.roster,
      enabled: Boolean(user.roster?.length),
    })
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans

  const noTherapistForPatient: boolean =
    patientCarePlans?.length &&
    !resultProviders.some((el: ResultGetProviders) => el.therapists?.length)

  const bookNewSession = () => {
    onClickBookNewSession()

    if (Boolean(patient) && !patientCarePlans?.length) {
      navigate('/onboarding/area-focus', {
        state: { skipAddAnotherPatient: true },
      })
      return
    }

    const goToSelectServiceLine = !patient || clientAvailableSLs?.length > 1

    if (goToSelectServiceLine) navigate('/select-service-line')
    else {
      const currentCarePlan = patientCarePlans[0]
      const providerToBookWith = resultProviders
        ?.find((r) => r.patient.id === patient.id)
        ?.therapists?.find((t) => t.id === currentCarePlan.providerId)
      const serviceLineUrl = SERVICE_LINES_ARRAY.find(
        (line: ServiceLine) => line.displayName === currentCarePlan.displayName
      ).url

      let nextRouteUrl = ''

      if (!providerToBookWith) {
        const currentProviderFromSessions =
          currentCarePlan?.sessions?.[0]?.therapist

        const changedState =
          Boolean(currentProviderFromSessions) &&
          !currentProviderFromSessions?.licenseCredentials?.includes(
            `US-${states.find((s) => s.name === patient?.state)?.abbrev}`
          )

        nextRouteUrl = `/booking/${serviceLineUrl}?changedState=${changedState}`
      } else
        nextRouteUrl = `/booking/${serviceLineUrl}/providers/${providerToBookWith.id}`

      navigate(nextRouteUrl, { state: { from: '/dashboard' } })
    }
  }

  useEffect(() => {
    if (
      !user ||
      isLoadingClientAvailableSLs ||
      !resultProviders?.length ||
      clientAvailableSLs?.length > 1 ||
      (user?.roster?.filter((p: Patient) => !p.isDisabled)?.length > 1 &&
        !patient) ||
      patient?.isDisabled
    )
      return

    const serviceLine = clientAvailableSLs[0]

    let currentPatient = null
    if (!patient) currentPatient = user.roster.find((p) => !p.isDisabled)
    else currentPatient = user.roster.find((p) => p.id === patient.id)

    const patientCarePlans: CarePlan[] = currentPatient?.conditions?.find(
      (c: Condition) => !c.isIep
    )?.carePlans

    if (!currentPatient || !serviceLine || !patientCarePlans?.length) return

    const currentCarePlan = patientCarePlans?.find(
      (cp: CarePlan) => cp.displayName === serviceLine.displayName
    )

    const currentProvider: Therapist = resultProviders
      ?.find((r: ResultGetProviders) => r.patient.id === currentPatient.id)
      ?.therapists?.find((t: Therapist) => t.id === currentCarePlan?.providerId)

    const stateAbbrev = states.find(
      (s) => s.name === currentPatient?.state
    )?.abbrev

    const currentProviderIsInSameState: boolean =
      currentProvider?.licenseCredentials?.includes(`US-${stateAbbrev}`)

    const providerNotAvailableForPatient: boolean =
      Boolean(currentProvider) &&
      currentProviderIsInSameState &&
      !currentProvider?.firstAvailability

    const bookableLinesForPatient = resultProviders.find(
      (r) => r.patient.id === currentPatient.id
    ).availableServiceLinesNames

    setPatient(currentPatient)
    setBookableLines(
      bookableLinesForPatient.map((lineName: string) =>
        SERVICE_LINES_ARRAY.find(
          (line: ServiceLine) => line.displayName === lineName
        )
      )
    )
    setProviderNotAvailable(providerNotAvailableForPatient)
  }, [isLoadingClientAvailableSLs, resultProviders, user])

  const isDisabled =
    (user.roster.length === 1 || Boolean(patient)) &&
    (isDisabledBooking ||
      noTherapistForPatient ||
      isLoadingUseGetProviders ||
      isLoadingClientAvailableSLs)

  if (
    (Boolean(patient) &&
      (patient?.isIepOnly ||
        patient?.isDisabled ||
        !patient.state ||
        (patient.isSeparateLogin
          ? !patient.allowedToBook || !patient.ableToBookAndCancel
          : !patient.allowedToBook) ||
        !patient?.conditions?.some(
          (c) => !c.isIep && c?.carePlans?.length > 0
        ))) ||
    user.roster.every((p: Patient) => p?.isDisabled) ||
    isLoadingClientAvailableSLs
  )
    return null

  return (
    <BookingStatusTooltip
      bookableLines={bookableLines}
      patient={user.roster.length === 1 ? user.roster[0] : patient}
      serviceLine={
        (Boolean(patient) && clientAvailableSLs?.length === 1) ||
        clientAvailableSLs?.length === 1
          ? clientAvailableSLs?.[0]
          : null
      }
      providerNotAvailable={providerNotAvailable}
      position={tooltipPosition}
      setIsDisabled={setIsDisabledBooking}
    >
      {dottedLargeBtn ? (
        <DottedLargeButton
          onClick={bookNewSession}
          title="Book New Session"
          disabled={isDisabled}
        />
      ) : withAvatarsBtn || withSpecificAvatars?.length ? (
        <button
          className={`${primaryButtonClass} w-full !gap-4 !p-8 xs:!p-2`}
          onClick={bookNewSession}
          disabled={isDisabled}
        >
          <PlusIcon className="h-5 w-5" />
          Book New Session For
          <div className="flex">
            {React.Children.toArray(
              (withAvatarsBtn
                ? user?.roster?.filter((p: Patient) => !p.isDisabled)
                : withSpecificAvatars
              )
                ?.slice(0, 3)
                ?.map((p: Patient) => (
                  <img
                    src={getAvatarSrc(user, p)}
                    alt="self"
                    className="-mr-3 h-8 w-8"
                  />
                ))
            )}
          </div>
        </button>
      ) : (
        <button
          className={`${secondaryButtonClass} !bg-white xs:w-full`}
          onClick={bookNewSession}
          disabled={isDisabled}
        >
          {isLoadingUseGetProviders ? (
            <RefreshIcon className="loader h-5 w-5" />
          ) : (
            <PlusIcon className="h-5 w-5" />
          )}{' '}
          Book New Session
        </button>
      )}
    </BookingStatusTooltip>
  )
}

export default BookNewSessionBtn
