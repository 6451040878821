import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  ExclamationCircleIcon,
  PlusIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { ExclamationIcon, TrashIcon } from '@heroicons/react/outline'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useController, useForm } from 'react-hook-form'

import { usePatient } from '../../../contexts/PatientProvider'
import { newDate } from '../../../helpers/generic'
import {
  deleteButtonClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import { useAuth } from '../../../contexts/AuthProvider'
import CenteredModal from '../../../components/CenteredModal'
import UPLOAD_ICON from '../../../assets/icons/Upload.svg'
import PREVIEW_IMAGE from '../../../assets/images/previewImage.png'
import { useUploadNewDocument } from '../../../mutations/dashboard/UploadNewDocument'
import { useToastContext } from '../../../contexts/ToastContext'
import { useDeleteDocument } from '../../../mutations/dashboard/DeleteDocument'
import { useGetDocuments } from '../../../queries/dashboard/GetDocuments'
import { useDownloadDocument } from '../../../mutations/dashboard/DownloadDocument'
import { useQueryClient } from '@tanstack/react-query'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

export interface DisplayDocument {
  name: string
  addedBy: string
  date: number
  id: string
}

export interface NewDocumentForm {
  name: string
  file: File
  patientId: string
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  file: Yup.mixed()
    .required('File is required')
    .test('fileFormat', 'Invalid file format', (value) => {
      if (!value) return true // Skip validation if no file is selected
      const supportedFormats = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
      ]
      return supportedFormats.includes(value.type)
    })
    .test('fileSize', 'File size is too large', (value) => {
      if (!value) return true // Skip validation if no file is selected
      const maxSizeInBytes = 12 * 1024 * 1024 // 12MB
      return value.size <= maxSizeInBytes
    }),
})

const DocumentsTab: React.FC = () => {
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { user } = useAuth()
  const { patient } = usePatient()
  const { patientId } = useParams()
  const [documents, setDocuments] = useState<DisplayDocument[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [file, setFile] = useState<File>(null)
  const { data: initialDocuments, isLoading: isLoadingDocuments } =
    useGetDocuments({
      patientId,
    })
  const { mutate: callUploadNewDocument, isLoading: isLoadingUpload } =
    useUploadNewDocument()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
    getValues,
  } = useForm<NewDocumentForm>({
    defaultValues: {
      name: '',
      file: null,
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const { field: fieldFile } = useController({
    control: control,
    name: 'file',
    defaultValue: null,
  })

  const onSubmit = (data: NewDocumentForm) =>
    callUploadNewDocument(
      { ...data, patientId },
      {
        onSuccess: (documentId: string) => {
          const updatedDocuments = [
            ...documents,
            {
              name: data.name,
              addedBy: 'ACCOUNT_HOLDER',
              date: new Date().getTime(),
              id: documentId,
            },
          ]
          queryClient?.setQueryData(
            ['getDocuments', patientId],
            updatedDocuments
          )
          setDocuments(updatedDocuments)

          trackMixPanel({
            eventName: MIXPANEL_EVENT.DOCUMENT_UPLOADED,
            properties: {
              accountId: user.data.id,
              source: 'Profile',
              update: 'Add',
            },
          })

          addToast('success', 'Document uploaded successfully!')
        },
        onError: () => addToast('error', 'Error uploading document.'),
        onSettled: () => setOpen(false),
      }
    )

  const onHandleChangeFile = (selectedFile: File) => {
    if (
      !['image/png', 'image/jpeg', 'application/pdf'].includes(
        selectedFile.type
      )
    )
      return
    setFile(selectedFile)
    setValue('file', selectedFile)
  }

  useEffect(() => {
    if (isLoadingDocuments) return
    setDocuments(initialDocuments)
  }, [isLoadingDocuments])

  useEffect(() => {
    setFile(null)
    reset()
  }, [open])

  return (
    <div className="flex flex-col gap-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <p className="font-mediu text-2xl xs:text-base xs:font-semibold">
          My Documents
        </p>

        {/* Add */}
        {!patient.isDisabled && (
          <div className="flex justify-end">
            <button
              className={tertiaryButtonClass}
              type="button"
              onClick={() => setOpen(true)}
            >
              <PlusIcon className="h-5 w-5" /> <span>Add</span>
            </button>
          </div>
        )}
      </div>

      {/* Documents */}
      {isLoadingDocuments ? (
        <div className="flex flex-row items-center justify-center gap-2 text-text-primary">
          <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
          <p>Loading documents...</p>
        </div>
      ) : documents?.length > 0 ? (
        React.Children.toArray(
          documents
            .sort(
              (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
            )
            .map((d: DisplayDocument) => (
              <DocumentRow
                d={d}
                setDocuments={setDocuments}
                documents={documents}
              />
            ))
        )
      ) : (
        <p className="text-center text-base text-text-secondary">
          You don't have any documents.
        </p>
      )}

      {/* Add document modal */}
      <CenteredModal setOpen={setOpen} open={open}>
        <p className="mb-6 text-lg font-semibold xs:text-center xs:text-base">
          Add a document
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          {/* Name */}
          <div>
            <label
              htmlFor="otherInsuranceName"
              className="block text-base font-semibold xs:text-sm"
            >
              Document name
            </label>
            <div className="relative mt-1">
              <input
                type="text"
                className={`${errors.name ? inputErrorClass : inputValidClass}`}
                placeholder="Enter"
                {...register('name')}
              />
              {errors.name && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            <p className="mt-2 text-sm text-status-error">
              {errors.name?.message}
            </p>
          </div>

          {/* File */}
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="file"
                className="block text-base font-semibold xs:text-sm"
              >
                Select a file
              </label>
              <p className="text-sm font-normal">pdf, png or jpg (max 12 MB)</p>
            </div>
            <div
              onDrop={(event) => {
                event.preventDefault()
                onHandleChangeFile(event.dataTransfer.files[0])
              }}
              onDragOver={(event) => event.preventDefault()}
              className="mt-1 flex items-center justify-center gap-1 rounded-md border border-dashed border-components-fields bg-components-fillBorders p-8 shadow-sm"
            >
              <img src={UPLOAD_ICON} alt="upload_icon" />
              <p className="text-base">
                Drop file to upload or{' '}
                <label
                  htmlFor="file-input"
                  className="cursor-pointer text-cta-default underline"
                >
                  Browse
                </label>
              </p>
              <input
                type="file"
                id="file-input"
                className="hidden"
                accept="image/png, image/jpeg, application/pdf"
                {...fieldFile}
                onChange={(event) => {
                  event.preventDefault()
                  onHandleChangeFile(event.target.files[0])
                }}
                value=""
              />
            </div>
            {errors.file?.message && (
              <p className="mt-2 text-sm text-status-error">
                {errors.file?.message}
              </p>
            )}
          </div>

          {/* Preview file */}
          {file && (
            <div className="flex w-1/2 items-center justify-between rounded border border-components-fields p-2">
              <div className="flex items-center gap-2">
                <img
                  src={
                    ['image/png', 'image/jpeg'].includes(file.type)
                      ? URL.createObjectURL(file)
                      : PREVIEW_IMAGE
                  }
                  alt="preview_image"
                  className="h-12 object-contain"
                />
                <div className="flex flex-col">
                  <p className="w-36 truncate text-sm font-semibold xs:w-18">
                    {file.name}
                  </p>
                  <p className="text-xs font-normal">
                    {Math.round(file.size / 1000)} KB
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setFile(null)
                  setValue('file', null)
                }}
              >
                <TrashIcon className="h-4 w-4 text-text-label" />
              </button>
            </div>
          )}

          {/* Buttons */}
          <div className="flex w-full justify-end gap-4">
            <button
              className={primaryButtonClass}
              type="submit"
              disabled={
                isLoadingUpload ||
                isSubmitting ||
                Object.keys(errors).length !== 0 ||
                !file ||
                !getValues('name')
              }
            >
              {isLoadingUpload || isSubmitting ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-white" /> Loading
                </>
              ) : (
                'Save'
              )}
            </button>
            <button
              type="button"
              className={tertiaryButtonClass}
              onClick={() => setOpen(false)}
              disabled={isSubmitting || isLoadingUpload}
            >
              Cancel
            </button>
          </div>
        </form>
      </CenteredModal>
    </div>
  )
}

export default DocumentsTab

const DocumentRow: React.FC<{
  d: DisplayDocument
  documents: DisplayDocument[]
  setDocuments: React.Dispatch<React.SetStateAction<DisplayDocument[]>>
}> = ({ d, documents, setDocuments }) => {
  const queryClient = useQueryClient()
  const addToast = useToastContext()
  const { user } = useAuth()
  const { patient } = usePatient()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const { mutate: callDeleteDocument, isLoading: isLoadingDelete } =
    useDeleteDocument()
  const { mutate: callDownloadDocument, isLoading: isLoadingDownload } =
    useDownloadDocument()

  const deleteDocument = () =>
    callDeleteDocument(
      { id: d.id },
      {
        onSuccess: () => {
          const updatedDocuments = documents.filter((doc) => doc.id !== d.id)
          queryClient?.setQueryData(
            ['getDocuments', patient.id],
            updatedDocuments
          )
          setDocuments(updatedDocuments)
          addToast('success', 'Document deleted!')
          setOpenDelete(false)
        },
        onError: () => addToast('error', 'Something went wrong!'),
      }
    )

  const downloadDocument = () =>
    callDownloadDocument(
      { id: d.id },
      {
        onSuccess: (downloadUrl: string) => {
          trackMixPanel({
            eventName: MIXPANEL_EVENT.FILE_DOWNLOADED,
            properties: {
              fileType: 'Document',
            },
          })

          window.open(downloadUrl, '_blank')
        },
        onError: () => addToast('error', 'Something went wrong!'),
      }
    )

  return (
    <>
      <div className="flex flex-row items-center justify-between rounded-lg border border-components-fields p-4 sm:px-10 sm:py-10 xs:flex-wrap">
        <p className="flex-[0.4] font-semibold xs:w-full xs:flex-[0.5]">
          {d.name}
        </p>
        <p className="flex-[0.25] text-sm xs:hidden">
          added by{' '}
          {d.addedBy === 'Huddle Up'
            ? 'Huddle Up'
            : d.addedBy === 'ACCOUNT_HOLDER'
            ? user.data.firstName
            : '-'}
        </p>
        <p className="flex-[0.15] text-sm xs:flex-[0.3]">
          {format(newDate(new Date(d.date), patient.timeZone), 'MM/dd/yyyy')}
        </p>
        <div className="flex flex-[0.2] items-center justify-end gap-18 xs:gap-4">
          {d.addedBy === 'ACCOUNT_HOLDER' && (
            <button onClick={() => setOpenDelete(true)}>
              <TrashIcon className="h-6 w-6 text-text-label" />
            </button>
          )}
          <button onClick={downloadDocument} className="text-text-primary">
            {isLoadingDownload ? (
              <RefreshIcon className="loader h-6 w-6" />
            ) : (
              <DownloadIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Delete document modal */}
      <CenteredModal setOpen={setOpenDelete} open={openDelete}>
        <div className="flex flex-col gap-6 text-text-primary">
          <div className="flex flex-row gap-4">
            <div className="self-start rounded-full bg-status-error bg-opacity-20 p-2.5">
              <ExclamationIcon className="h-5 w-5 text-status-error" />
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-lg font-semibold">Delete {d.name}?</p>
              <p>
                Are you sure you would like to delete this document? This action
                cannot be undone.
              </p>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <button
              className={deleteButtonClass}
              onClick={deleteDocument}
              disabled={isLoadingDelete}
            >
              {isLoadingDelete ? (
                <RefreshIcon className="loader h-6 w-6" />
              ) : (
                'Delete'
              )}
            </button>
            <button
              className={tertiaryButtonClass}
              onClick={() => setOpenDelete(false)}
              disabled={isLoadingDelete}
            >
              Cancel
            </button>
          </div>
        </div>
      </CenteredModal>
    </>
  )
}
