import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from 'react'
import type { User as FirebaseUser } from 'firebase/auth'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import mixpanel from 'mixpanel-browser'

import { auth } from '../config/firebase'
import type { User } from '../types/User'
import { useGetUserData } from '../mutations/user/GetUserData'
import type { Patient } from '../types/Patient'
import { getPatientType } from '../helpers/utils'

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN
mixpanel.init(mixpanelToken)

export interface AuthContextModel {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  initialized: boolean
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>
  setAuthError: React.Dispatch<React.SetStateAction<string>>
  authError: string
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
)

export const useAuth = (): AuthContextModel => useContext(AuthContext)

export const AuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [user, setUser] = useState<User>(null)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [authError, setAuthError] = useState<string>(null)

  const { mutate: callGetUserData } = useGetUserData()

  const contextValue = useMemo(
    () => ({
      authError,
      setAuthError,
      user,
      setUser,
      initialized,
      setInitialized,
    }),
    [authError, setAuthError, user, setUser, initialized, setInitialized]
  )

  const addInfoToMixpanel = (u: User) => {
    // set account holder
    mixpanel.identify(u.data.id)
    mixpanel.people.set({
      $anon_distinct_id: u.data.id,
      $first_name: u.data.firstName,
      $last_name: u.data.lastName,
      $email: u.data.email,
      $vanity_url: u.data.clientData.vanityUrl,
      $is_account_holder: true,
      $role: 'Account Holder',
    })

    // set each patient
    u.roster?.forEach((p: Patient) => {
      mixpanel.identify(p.id)
      mixpanel.people.set({
        $anon_distinct_id: p.id,
        $first_name: p.firstName,
        $last_name: p.lastName,
        $email: u.data.email,
        $dob: p.birthDate,
        $gender: p.gender,
        $state: p.state,
        $relationship: p.relationship?.name,
        $vanity_url: u.data.clientData.vanityUrl,
        $account_type: getPatientType(p),
        $created: new Date().toLocaleString(),
        $role: 'Patient',
      })
    })
  }

  useEffect(() => {
    let unsubscribeAuth = () => {}

    unsubscribeAuth = onAuthStateChanged(auth, async (u: FirebaseUser) => {
      const isUserVerified = u?.emailVerified

      if (!isUserVerified) {
        setUser(null)
        setInitialized(true)
      } else {
        callGetUserData(null, {
          onError: () => {
            setAuthError('auth/something-wrong')
            signOut(auth)
          },
          onSuccess: async (resultUserData: User) => {
            addInfoToMixpanel(resultUserData)
            setUser(resultUserData)
            setInitialized(true)
          },
        })
      }
    })

    return () => {
      unsubscribeAuth()
    }
  }, [])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
